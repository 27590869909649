<template>
  <div class="report">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-arrow
      @click-left="onClickLeft"
    />
    <p class="title"><span style="color: #EE394E">*</span>投诉/建议内容</p>
    <van-field
      class="text"
      v-model="message"
      rows="2"
      autosize
      type="textarea"
      maxlength="200"
      placeholder="请输入投诉的详细内容"
      show-word-limit
    />
    <p class="title">
      <van-uploader :after-read="afterRead" v-model="fileList" :max-count="4"/>
    </p>
    <p class="title"><span style="color: #EE394E">*</span>手机号码</p>
    <van-field v-model="phoneNumber" placeholder="请输入手机号" type="number" maxlength="11"/>
    <div class="report_btn_wrapper">
      <van-button type="info" class="report_btn" @click="submit">提交投诉/建议</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report',
  data () {
    return {
      message: null,
      phoneNumber: null,
      fileList: [],
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    submit () {
      if (!this.message) {
        this.$toast('请输入投诉内容')
        return
      }
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!phone.test(this.phoneNumber)) {
        this.$toast('请输入正确的手机号')
        return
      }
      this.$toast('已收到您的投诉，感谢您对平台环境做出的贡献。')
      setTimeout(() => {
        this.$router.replace({
          path: `/home2/${this.$store.state.userId}`
        })
        this.phoneNumber = null
        this.message = null
      }, 2000);
    }
  }
}
</script>

<style lang="less" scoped>
  .report{
    // background: #191d3d;
    min-height: 100%;
    .van-radio-group {
      margin: 20px 0;
      .van-radio {
        margin: 10px 20px;
      }
    }
    .title {
      margin: 20px;
      font-weight: 500;
      font-size: 15px;
      text-align: left;
      // color: #fff;
    }
    .text {
      width: 90%;
      margin: 0 auto;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
    }
    .report_btn_wrapper {
      display: flex;
      margin-top: 50px;
      .report_btn {
        width: 95%;
        margin: 0 auto;
        font-weight: 500;
        font-size: 18px;
        border-radius: 10px;
      }
    }
  }
  /ddep/ .van-radio__label {
    color: #fff;
  }
</style>